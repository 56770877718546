import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    optionAttributeName: String,
    targetElementAttributeName: String,
  }

  static targets = [
    "sourceElement",
    "optionElement",
    "targetElement"
  ]

  connect() {
    this.update()
  }

  update(_event) {
    const { value } = this.sourceElementTarget
    const option = this.optionElementTargets.find(e => e.value == value)
    const settingValue = option.getAttribute(this.optionAttributeNameValue)

    this.targetElementTargets.forEach(e => e.setAttribute(this.targetElementAttributeNameValue, settingValue))
  }
}
