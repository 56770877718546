import CableReady from "cable_ready"
import { stripHtmlComments, createFragment } from "../lib/helpers"
import { Flash } from "../lib/flash"

CableReady.operations.originalMorph = CableReady.operations.morph

CableReady.operations.morph = operation => {
  let { html } = operation
  operation.html = stripHtmlComments(html)
  CableReady.operations.originalMorph(operation)
}

CableReady.operations.flash = operation => Flash[operation.type](operation.message)
CableReady.operations.click = operation => document.querySelector(operation.selector)?.click()
CableReady.operations.expand = CableReady.operations.click
CableReady.operations.collapse = CableReady.operations.click
CableReady.operations.clearCache = _operation => Turbo.cache.clear()
CableReady.operations.replaceRootHtml = operation => {
  let { html } = operation

  const fragment = createFragment(html)
  const fragmentRoot = fragment.querySelector(operation.selector)
  const elementToBeReplaced = document.querySelector(operation.selector)
  elementToBeReplaced.outerHTML = fragmentRoot.outerHTML
}

CableReady.operations.redirectToWithoutCache = operation => {
  if (!operation.url) {
    operation.url = window.location.href
  }

  CableReady.operations.clearCache(operation)

  operation.action = operation.action || "replace"
  CableReady.operations.redirectTo(operation)
}

CableReady.operations.crFetch = operation => {
  setTimeout(() => {
    mrujs
      .fetch(operation.url, {
        headers: { "Accept": "text/vnd.cable-ready.json; charset=utf-8" }
      })
      .then(resp => resp.json())
      .then(CableReady.perform)
  }, operation.delay || 0)
}

CableReady.operations.updateProgressBar = operation => {
  const progressBar = document.querySelector(operation.selector)
  progressBar.style.width = `${operation.value}%`
  progressBar.setAttribute("aria-valuenow", operation.value)
  progressBar.innerText = `${operation.value}%`
}

CableReady.operations.openInNewWindow = operation => {
  window.open(operation.url, "_blank")
}
CableReady.operations.downloadFile = operation => {
  const link = document.createElement('a')
  link.href = operation.url

  // determine if it's a cross origin....
  if (operation.url.startsWith("/") || operation.url.startsWith(window.location.origin)) {
    // we can download the file... so let's do that
    link.download = operation.filename || operation.url.substr(operation.url.lastIndexOf('/') + 1)
  } else {
    // we need to open the file in a new tab
    link.target = "_blank"
  }
  link.click()
}

CableReady.operations.reloadTurboFrame = operation => document.querySelector(operation.selector)?.reload()

CableReady.operations.addSlimSelectOption = ({ text, value, selected, selector }) => {
  const selectElement = document.querySelector(selector)
  if (!selectElement) { return }

  const slimSelect = selectElement["slimSelect"]
  if (!slimSelect) { return }

  slimSelect.addOption({ text, value, selected })
}